/**
 * Minimal Paper Card
 * This is similar to the paper card but it contains less content.
 * Use this card to display a 'tag' along with a title
 */
import React from "react"
import { Link } from "gatsby"

export const MinimalPaperCard = ({ tag, title, to }) => {
  return (
    <Link
      to={to}
      className="text-indigo-800 flex items-center transition hover:bg-white"
    >
      <span className="p-2 md:px-5 bg-indigo-900 text-xs text-white lg:mr-8">
        {tag}
      </span>

      <span className="p-2 lg:pr-5 text-sm md:text-base">{title}</span>
    </Link>
  )
}
