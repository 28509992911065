/**
 * The media card is an image rich card that is decorated with
 * a background image and is used to link media based content
 * for example, training videos.
 */

import React from "react"
import { Link } from "gatsby"

export const MediaCard = ({ title, subTitle, imageSrc, isPrivate, to }) => {
  return (
    <Link to={to} className="group">
      <div className="aspect-w-16 aspect-h-9 bg-black rounded transition group-hover:shadow-xl">
        {imageSrc ? (
          <img
            className="z-0 rounded object-cover transition opacity-70 group-hover:opacity-90"
            src={imageSrc}
            alt={title}
          />
        ) : (
          <div className="z-0 rounded object-cover bg-indigo-600 transition opacity-70 group-hover:opacity-90" />
        )}

        <div className="absolute">
          <div className="w-full h-full lg:py-8 p-4 z-10 text-white transition">
            <h3 className="serif md:text-xl font-bold">{title}</h3>
            <span className="block mt-3 text-xs md:text-sm font-medium opacity-0 group-hover:opacity-100">
              {subTitle}
            </span>
          </div>

          {isPrivate && (
            <span className="absolute text-xs bg-yellow-500 rounded-sm px-2 py-0.5 font-semibold bottom-3 right-3">
              Members
            </span>
          )}
        </div>
      </div>
    </Link>
  )
}
