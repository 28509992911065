import React from "react"
import { graphql } from "gatsby"
import { Paper } from "../components/paper"
import { MediaCard } from "../components/media-card"
import { MinimalPaperCard } from "../components/paper/MinimalPaperCard"

import Banner from "../components/banner"
import Layout from "../components/layout"

const Training = ({ data }) => {
  const { fellowships, videos } = data

  return (
    <Layout>
      <Banner>Training &amp; Education</Banner>

      {videos && videos.edges.length > 0 && (
        <section id="videoList" className="py-16">
          <div className="container mx-auto px-3">
            <ul className="flex flex-wrap">
              {videos.edges.map((video) => {
                const { node } = video

                console.log("this is the node", node)

                return (
                  <li
                    className="w-full md:w-1/2 xl:w-1/3 p-1 lg:p-3"
                    key={node.id}
                  >
                    <MediaCard
                      imageSrc={node.featureImage?.asset.url}
                      title={node.name}
                      subTitle={node.author}
                      isPrivate={node.restricted}
                      to={`/training-and-education/videos/${node.slug.current}`}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      )}

      <section id="fellowships" className="bg-gray-100 py-16">
        <div className="container mx-auto px-3">
          <div className="prose lg:prose-lg">
            <h2 className="serif">Fellowships</h2>
            <p className="font-medium text-gray-600">
              In your final year of training to become a musculoskeletal
              radiologist you may wish to undertake a fellowship attachement to
              learn new skills.
            </p>
          </div>

          {fellowships && fellowships.edges.length > 0 && (
            <ul className="flex flex-wrap my-12 lg:-mx-3">
              {fellowships.edges
                .filter((item) => !item.node.isPastEvent)
                .map((item) => {
                  const { node } = item

                  return (
                    <li
                      className="w-full lg:w-1/2 xl:w-1/4 p-1 lg:p-3"
                      key={node.id}
                    >
                      <Paper
                        to={`/training-and-education/fellowship/${node.slug.current}`}
                        title={node.description}
                        date={node.publishedAt}
                      />
                    </li>
                  )
                })}
            </ul>
          )}

          <div className="prose lg:prose-lg mb-5">
            <h3 className="serif">Past Fellowships</h3>
          </div>

          <div className="flex font-bold">
            {fellowships.edges
              .filter((item) => item.node.isPastEvent)
              .map((item) => {
                const { node } = item

                return (
                  <div
                    className="w-full lg:w-1/2 xl:w-1/3 p-1 lg:p-3"
                    key={node.id}
                  >
                    <MinimalPaperCard
                      to={`/training-and-education/fellowship/${node.slug.current}`}
                      title={node.description}
                      tag={node.publishedAt}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Training

export const query = graphql`
  query {
    videos: allSanityVideo(sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          name
          author
          restricted
          featureImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
      }
    }

    fellowships: allSanityFellowship {
      edges {
        node {
          id
          description
          isPastEvent
          publishedAt(formatString: "MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`
